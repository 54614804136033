<template>
  <div class="backlog">
    <van-tabs v-model="active" sticky animated swipe-threshold='1'>
      <van-tab v-for="(el, index) in Treelist" :key="index">
        <template #title>
          <span @click="activeClick(el)" class="engineeringSpan">{{
              el.name
          }}</span>
        </template>
        <!-- 流程表单 -->
        <van-list v-model="loading" :finished="finished" style="margin-top: 10px" finished-text="没有更多了" @load="getData"
          :immediate-check="false" v-if="active == 4">
          <div v-if="list.length > 0">
            <div>
              <div class="menuStyle" v-for="(item, index) in list" :key="index" @click="FindFormApplyDetail(item)">
                <div class="flexcolumn">
                  <div class="flexLR" style="width: 100%;justify-content: start;">
                    <div class="flexLR" style="width: 20%">
                      <img :src="$store.state.baseUrl + item.user13" width="30" style="border-radius: 50%" alt="" />
                      <div style="font-size: 14px">{{ item.user04 }}</div>
                    </div>
                    <div style="color: #bdbdbd">{{ item.famp10 }}</div>
                    <div class="bd">流程表单</div>
                  </div>
                  <div class="flexLR" style="width: 80%; justify-content: start">
                    <div style="color: red; width: 28%" v-show="item.famp13 == 1" class="flex">
                      <van-icon name="clock-o" size="16" />催办
                    </div>
                    <div style="
                        font-size: 14px;
                        font-weight: bold;
                        margin-left: 20px;
                      ">
                      {{ item.fmap03 }}
                    </div>
                  </div>
                  <div class="flexLR" style="border-top: 1px solid #f1f1f1; padding-top: 10px">
                    <div>
                      <p>审核:</p>
                      {{ item.auditUsers }}
                    </div>
                  </div>
                </div>
                <span v-if="item.famp11 == 1" style="background-color: #ff8100">待审核</span>
                <span v-else-if="item.famp11 == 2" style="background-color: #0095ff">已通过</span>
                <span v-else-if="item.famp11 == 3">已驳回</span>
                <span v-else>已撤回</span>
              </div>
            </div>
          </div>
          <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
            <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
            <div>暂无数据</div>
          </div>
        </van-list>

        <!-- 进度上报 -->
        <van-list v-model="loading" :finished="finished" style="margin-top: 10px" finished-text="没有更多了" @load="getData"
          :immediate-check="false" v-else-if="active == 2">
          <div v-if="list.length > 0">
            <div>
              <div class="menuStyle" v-for="(item, index) in list" :key="index" @click="
                $router.push({
                  path: '/home/menu/schedule',
                  query: {
                    obj: encodeURIComponent(JSON.stringify({
                      id: item.pldt03,
                      parentId: item.pldt01,
                      name: item.pldt04,
                      pjpl11: item.pjpl11,
                    })),
                  },
                })
              ">
                <div class="flexcolumn">
                  <div class="flexLR" style="width: 100%">
                    <div class="flexLR" style="width: 100%; overflow-y: hidden;justify-content:flex-start">
                      <img :src="$store.state.baseUrl + item.pldt12List[0].user13" width="30" style="border-radius: 50%"
                        alt="" />
                      <div style="font-size: 14px; white-space: pre">
                        {{ item.pldt12Name }}
                      </div>
                    </div>
                  </div>
                  <div class="flexLR">
                    <div style="color: #bdbdbd">{{ item.pldt20 }}</div>
                    <div class="bd">进度上报</div>
                  </div>
                  <div class="flexLR" style="width: 100%; justify-content: start; padding: 20px">
                    <div style="color: red; width: 20%" v-if="
                      isShow(item) <= 2 && item.pldt05 != 100 ? true : false
                    " class="flex">
                      <van-icon name="warning-o" size="16" />
                      预警
                    </div>
                    <div style="color: #ff8100; width: 20%" v-else class="flex">
                      <van-icon name="clock-o" size="16" />
                      延期
                    </div>
                    <div style="
                        font-size: 14px;
                        font-weight: bold;
                        margin-left: 20px;
                      ">
                      {{ item.pldt04 }}
                    </div>
                  </div>
                  <div class="flexLR" style="border-top: 1px solid #f1f1f1; padding-top: 10px">
                    <div>
                      <p>计划结束时间:</p>
                      {{ item.pldt07 }}
                    </div>
                  </div>
                </div>
                <span>未完成</span>
              </div>
            </div>
          </div>
          <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
            <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
            <div>暂无数据</div>
          </div>
        </van-list>

        <!-- 工单任务 -->
        <van-list v-model="loading" :finished="finished" style="margin-top: 10px" finished-text="没有更多了" @load="getData"
          :immediate-check="false" v-else-if="active == 3">
          <div v-if="list.length > 0">
            <div>
              <div class="menuStyle" v-for="(item, index) in list" :key="index" @click="
                item.state == '未启动延误' || item.state == '待启动'
                  ? true
                  : $router.push({
                    path: '/home/menu/gdInfo',
                    query: { id: item.famp01 },
                  })
              ">
                <div class="flexcolumn">
                  <div class="flexLR" style="width: 100%">
                    <div class="flexLR" style="width: 20%">
                      <img :src="$store.state.baseUrl + item.user13" width="30" style="border-radius: 50%" alt="" />
                      <div style="font-size: 14px">{{ item.user04 }}</div>
                    </div>
                    <div style="color: #bdbdbd">{{ item.famp10 }}</div>
                    <div class="bd">工单任务</div>
                  </div>
                  <div class="flexLR" style="width:100%;">
                    <div style="color: red; width: 28%" v-show="item.famp13 == 1" class="flex">
                      <van-icon name="clock-o" size="16" />催办
                    </div>
                    <div style="
                        font-size: 14px;
                        font-weight: bold;
                        margin-left: 20px;
                      ">
                      {{ item.wkod02 }}
                    </div>
                    <div style="width:20px" @click="startBtn(item)"
                      v-if="item.state == '未启动延误' || item.state == '待启动' ? true : false">
                      <van-icon name="play-circle-o" size="20" />
                    </div>
                  </div>
                  <div class="flexLR" style="border-top: 1px solid #f1f1f1; padding-top: 10px">
                    <div>
                      <p>审核:</p>
                      {{ item.wkod08Name }}
                    </div>
                    <div>
                      <p>执行:</p>
                      {{ item.wkod19Name }}
                    </div>
                  </div>
                </div>
                <span :style="{
                  backgroundColor:
                    item.state == '未启动延误' || item.state == '待启动'
                      ? 'red'
                      : '#0095ff',
                }">{{ item.state }}</span>
              </div>
            </div>
          </div>
          <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
            <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
            <div>暂无数据</div>
          </div>
        </van-list>

        <!-- 安全管理 -->
        <van-list v-model="loading" :finished="finished" style="margin-top: 10px" finished-text="没有更多了" @load="getData"
          :immediate-check="false" v-else-if="active == 0">
          <div v-if="list.length > 0">
            <div>
              <div class="menuStyle" v-for="(item, index) in list" :style="item.fmtp09===2?'background-color:#ffe98d':''" :key="index" @click="
                  $router.push({
                    path: '/home/menu/SecurityPatrol',
                    query: { type: item.famp01 },
                  })
              ">
                <div class="flexcolumn">
                  <div class="flexLR" style="width: 100%;justify-content: inherit;">
                    <div class="flexLR" style="width: 20%">
                      <img :src="$store.state.baseUrl + item.user13" width="30" style="border-radius: 50%;height: 35px;width:35px" alt="" />
                    </div>
                    <div style="position: absolute;left:18%">
                      <div style="font-size: 14px">{{ item.user04 }}</div>
                      <div style="color: #bdbdbd">{{ item.famp10 }}</div>
                    </div>
                    <div class="bd">安全管理</div>
                  </div>
                  <div class="flexLR" style="justify-content: inherit;margin-top: 5px;">
                    <div style="color: red;width: 15%;" v-show="item.famp13 == 1" class="flex">
                      <van-icon name="clock-o" size="16" />催办
                    </div>
                    <div style="
                        font-size: 14px;
                        font-weight: bold;
                        width: 83%;
                      ">
                      {{
                        item.fafd06
                          ? item.fafd06.length >= 20
                            ? item.fafd06.substr(0, 20) + "..."
                            : item.fafd06
                          : ""
                      }}
                    </div>
                    <div style="width:20px" @click="startBtn(item)"
                      v-if="item.state == '未启动延误' || item.state == '待启动' ? true : false">
                      <van-icon name="play-circle-o" size="20" />
                    </div>
                  </div>
                  <div class="flexLR" style="border-top: 1px solid #f1f1f1; padding-top: 10px">
                    <div>
                      <p>审核:</p>
                      {{ item.auditUsers }}
                    </div>
                    <div>
                      <p>抄送:</p>
                      {{ item.CCUsers }}
                    </div>
                  </div>
                </div>
                <span :style="{
                  backgroundColor:
                    famp11(item.famp11) == '已驳回' || famp11(item.famp11) == '已撤回'
                      ? 'red'
                      : '#0095ff',
                }">{{ famp11(item.famp11) }}</span>
              </div>
            </div>
          </div>
          <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
            <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
            <div>暂无数据</div>
          </div>
        </van-list>
        
        <!-- 质量管理 -->
        <van-list v-model="loading" :finished="finished" style="margin-top: 10px" finished-text="没有更多了" @load="getData"
          :immediate-check="false" v-else>
          <div v-if="list.length > 0">
            <div>
              <div class="menuStyle" v-for="(item, index) in list" :style="item.fmtp09===2?'background-color:#ffe98d':''" :key="index" @click="
                  $router.push({
                    path: '/home/menu/QualityPatrol',
                    query: { type: item.famp01 },
                  })
              ">
                <div class="flexcolumn">
                  <div class="flexLR" style="width: 100%;justify-content: inherit;">
                    <div class="flexLR" style="width: 20%">
                      <img :src="$store.state.baseUrl + item.user13" width="30" style="border-radius: 50%;height: 35px;width:35px" alt="" />
                    </div>
                    <div style="position: absolute;left:18%">
                      <div style="font-size: 14px">{{ item.user04 }}</div>
                      <div style="color: #bdbdbd">{{ item.famp10 }}</div>
                    </div>
                    <div class="bd">质量管理</div>
                  </div>
                  <div class="flexLR" style="width:100%;justify-content: inherit;">
                    <div style="color: red;" v-show="item.famp13 == 1" class="flex">
                      <van-icon name="clock-o" size="16" />催办
                    </div>
                    <div style="
                        font-size: 14px;
                        font-weight: bold;
                        width: 83%;
                      ">
                      {{
                        item.fafd06
                          ? item.fafd06.length >= 20
                            ? item.fafd06.substr(0, 20) + "..."
                            : item.fafd06
                          : ""
                      }}
                    </div>
                    <div style="width:20px" @click="startBtn(item)"
                      v-if="item.state == '未启动延误' || item.state == '待启动' ? true : false">
                      <van-icon name="play-circle-o" size="20" />
                    </div>
                  </div>
                  <div class="flexLR" style="border-top: 1px solid #f1f1f1; padding-top: 10px">
                    <div>
                      <p>审核:</p>
                      {{ item.auditUsers }}
                    </div>
                    <div>
                      <p>抄送:</p>
                      {{ item.CCUsers }}
                    </div>
                  </div>
                </div>
                <span :style="{
                  backgroundColor:
                    famp11(item.famp11) == '已驳回' || famp11(item.famp11) == '已撤回'
                      ? 'red'
                      : '#0095ff',
                }">{{ famp11(item.famp11) }}</span>
              </div>
            </div>
          </div>
          <div v-else style="color: #0a9efc; margin-top: 100px; text-align: center">
            <img src="../../../assets/mobileImg/wu.png" width="60" alt="" />
            <div>暂无数据</div>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>
    <van-overlay z-index="999999" :show="show" @click="show = false">
      <van-loading style="top: 50%" vertical type="spinner" color="#1989fa"></van-loading>>
    </van-overlay>
  </div>
</template>

<script>
import {
  GetFormTask,
  GetPlanTask,
  GetWorkOrderTask,
  FindFormApplyDetail,
  UpdateWorkOrderTime,
} from "@/api/index";
export default {
  name: "dd_backlog",
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      show: false,
      finished: false,
      active: 0,
      pageSize: 0,
      Treelist: [
        { name: "安全管理", id: 0 },
        { name: "质量管理", id: 1 },
        { name: "进度上报", id: 2 },
        { name: "工单任务", id: 3 },
        { name: "流程表单", id: 4 },
      ],
    };
  },
  computed: {
    isShow: function () {
      return (i) => {
        var newTime = new Date();
        var dTime = new Date(i.pldt07N);
        return newTime - dTime < 0
          ? parseInt(Math.abs(newTime - dTime) / 1000 / 60 / 60 / 24)
          : 0;
      };
    },
    famp11() {
      return function (data) {
        switch (data) {
          case 1:
            return "待整改"
          case 2:
            return "已通过"
          case 3:
            return "已驳回"
          case 4:
            return "已撤回"
          case 5:
            return "待确认"
          default:
            return "未知状态"
        }
      }
    }
  },
  watch: {},
  methods: {
    async startBtn(item) {
      UpdateWorkOrderTime({ wid: item.wkod01, type: 1 }).then((res) => {
        if (res.Code == 1) {
          this.$Toast("启动成功");
          this.getData();
        }
      });
    },
    async FindFormApplyDetail(item) {
      this.show = true;
      let res = await FindFormApplyDetail({
        params: {
          id: item.fmap01,
        },
      });
      this.show = false;
      this.$router.push({ name: "bdInfo", params: { res: res.Data } });
    },
    activeClick(item) {
      this.active = item.id;
      this.list = [];
      this.getData();
    },
    async getData() {
      this.loading = true;
      // this.show = true;
      let res;
      if (this.active == 0) {
        res = await GetFormTask({
          params: {
            xid: this.$store.state.pid,
            uid: this.$store.state.uid,
            type: 2
          },
        });
      } else if (this.active == 2) {
        res = await GetPlanTask({
          params: {
            xid: this.$store.state.pid,
            uid: this.$store.state.uid,
            type: 1
          },
        });
      } else if (this.active == 3) {
        res = await GetWorkOrderTask({
          params: {
            xid: this.$store.state.pid,
            uid: this.$store.state.uid,
            type: 2
          },
        });
      }else if (this.active == 4){
        res = await GetFormTask({
          params: {
            xid: this.$store.state.pid,
            uid: this.$store.state.uid,
            type: 1
          },
        });
      } else {
        res = await GetFormTask({
          params: {
            xid: this.$store.state.pid,
            uid: this.$store.state.uid,
            type: 3
          },
        });
      }
      this.list = res.Data;

      this.finished = true;
      this.loading = false;
      // this.show = false;
    },
    updateData() {
      this.$emit("updateLoading");
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  mounted() { },
};
</script>
<style lang='scss' scoped>
.backlog {
  .flexLR {
    img {
      width: 60px;
      height: 60px;
    }
  }

  .menuStyle {
    position: relative;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 40px;
    border-radius: 20px;
    padding: 40px;
    padding-top: 50px;
    min-height: 220px;

    span {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      width: 150px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      color: white;
      border-radius: 0 20px 0 20px;
      background-color: red;
    }

    .flexcolumn {
      min-height: 220px;
      align-items: flex-start;
      justify-content: space-between;

      .flexLR {
        width: 100%;

        .bd {
          border: 2px solid rgb(0, 174, 255);
          color: rgb(0, 174, 255);
          padding: 0px 10px;
          border-radius: 30px;
        }

        p {
          color: #a9a9a9;
          display: inline-block;
        }
      }
    }
  }

  /deep/ .van-tabs__line {
    background-color: rgb(255, 255, 255);
  }

  /deep/ .van-tabs__nav {
    background-color: rgba(255, 255, 255, 0);
  }

  // /deep/ .van-tab{
  //   background-color: #2e6be6;
  // }
  // /deep/ .van-tabs__nav{
  //   margin-top:-6px;
  // }
  .engineeringSpan {
    color: white;
  }

  /deep/ .van-tab__text {
    padding: 0 10px;
  }

  /deep/ .van-tab--active .van-tab__text .engineeringSpan {
    position: relative;
    z-index: 1;
    min-width: 62px;
    text-align: center;
    display: block;
    color: rgb(0, 119, 255);

    &::after {
      content: "";
      position: absolute;
      left: -5px;
      z-index: -1;
      top: 0;
      background-color: rgb(255, 255, 255);
      width: 100%;
      padding: 0 5px;
      border-radius: 10px;
      height: 100%;
    }
  }

  /deep/ .van-tabs__content {
    height: 93%;
    overflow: hidden;
    overflow-y: auto;
  }

  .headTree {
    width: 100%;
    height: 40px;
    overflow: hidden;
    overflow-y: auto;
    background-color: red;
  }

  /deep/ .van-sticky--fixed .van-tabs__nav {
    background-color: #3767ed !important;
  }

  /deep/ .van-sticky--fixed .van-tabs__line {
    opacity: 0;
  }
}
</style>